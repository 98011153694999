<template>
  <CourseConfirm title="添加内容" :isShowBtn="true" @cancelFn="closeFn" @save="makeSubmit">
    <div class="edit-wrap">
      <div class="tab-wrap">
        <div class="tabs">
          <span class="tab cp" :class="{ active: requestParam.course_type == 1 }" @click="changeType(1)">直播课</span>
          <span class="tab cp" :class="{ active: requestParam.course_type == 3 }" @click="changeType(3)">录播课</span>
          <span class="tab cp" :class="{ active: requestParam.course_type == 2 }" @click="changeType(2)">小班课</span>
        </div>
        <!-- 搜索框 -->
        <div class="control-box">
          <div class="left-box">
            <el-button type="success" @click.stop="toCreatePage">新建课程</el-button>
          </div>
          <div class="right-box">
            <div class="select-wrap">
              <el-select placeholder="全部类型" v-model="requestParam.price_type" @change="changePriceType">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="select-wrap">
              <el-select placeholder="全部状态" v-model="requestParam.status" @change="changeStatus">
                <el-option v-for="item in options2" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </div>
            <div class="input-wrap">
              <el-input placeholder="输入课程名称搜索" v-model="requestParam.title" @keyup.enter.native="doSeach"></el-input>
            </div>
          </div>
        </div>
        <!-- 列表展示 -->
        <div class="ulBox simple-scrollar">
          <ul v-if="list != undefined && list.length > 0">
            <li
              class="courseItem cp"
              v-for="(item, index) in list"
              :key="index"
              @click.stop="getselectedContentId(resultObj[item.course_id] ? false : true, item)"
            >
              <el-checkbox v-model="flagArr[item.course_id]"></el-checkbox>
              <div class="little-mask-block"></div>
              <!-- @change="checked => getselectedContentId(checked, item)" -->
              <div class="courseImg">
                <img :src="item.photo" />
              </div>
              <div class="courseName">
                <div class="title">
                  {{ item.name }}
                </div>
                <div class="courseCate" v-if="item.price_type == 2">免费</div>
                <div class="courseCate" v-if="item.price_type == 1">￥{{ item.price }}</div>
                <div class="courseCate" v-if="item.price_type == 3">密码：{{ item.fee_pwd }}</div>
              </div>
            </li>
          </ul>
          <empty :isOld="false" :positionCenter="true" v-show="isShowEmpty"></empty>
          <infinite-loading :empty="false" @infinite="getList" spinner="spiral"></infinite-loading>
        </div>
      </div>
    </div>
    <loading v-show="isLoading" leftPos="0" pos="absolute"></loading>
  </CourseConfirm>
</template>
<script>
import CourseConfirm from '@/components/CourseConfirm'
import InfiniteLoading from '@/components/scrollLoad'
import empty from '@/components/Empty'
import API from '@/request/api'
import pageOverflow from '@/mixin/pageOverflow'
import loading from '@/components/LoadGif'

// import { emit } from "cluster";
export default {
  data() {
    return {
      requestParam: {
        course_type: '1',
        price_type: '',
        status: '',
        title: '',
        page: 1,
        is_series: 1
      },
      list: [],
      flagArr: {},
      options: [
        {
          value: '',
          label: '全部类型'
        },
        {
          value: '2',
          label: '免费'
        },
        {
          value: '1',
          label: '付费'
        },
        {
          value: '3',
          label: '密码'
        }
      ],
      options2: [
        {
          value: '',
          label: '全部状态'
        },
        {
          value: '1',
          label: '已上架'
        },
        {
          value: '2',
          label: '已下架'
        }
      ],
      resultObj: {},
      paths: [
        { path: '/courseLive/AddCourse', query: { courseType: '1' } },
        { path: '/coursePrivate/AddCourse', query: { courseType: '2' } },
        { path: '/courseRecord/AddCourse', query: { courseType: '3' } }
      ],
      isLoading: false,
      isShowEmpty: false
    }
  },
  mixins: [pageOverflow],
  components: {
    CourseConfirm,
    InfiniteLoading,
    empty,
    loading
  },
  props: {
    activeCourseId: [String, Number]
  },
  methods: {
    // 新建课程
    toCreatePage() {
      this.$router.push(this.paths[Number(this.requestParam.course_type) - 1])
    },
    closeFn() {
      this.$emit('closeFn')
    },
    //关键字搜索
    doSeach(e) {
      const keyCode = window.event ? e.keyCode : e.which
      if (keyCode == 13) {
        this.reLoadList()
      }
    },
    changePriceType() {
      this.reLoadList()
    },
    changeStatus() {
      this.reLoadList()
    },
    reLoadList() {
      if (this.finiteState) {
        this.requestParam.page = 1
        this.list = []
        this.$nextTick(this.finiteState.reset)
      }
    },
    changeType(type) {
      if (this.requestParam.course_type == type) {
        return
      }
      this.requestParam.course_type = type
      this.reLoadList()
    },
    getselectedContentId(checked, item) {
      if (this.resultObj[item.course_id]) {
        this.$delete(this.flagArr, [item.course_id])
        this.$delete(this.resultObj, [item.course_id])
        return
      }
      this.resultObj = Object.assign({}, this.resultObj, {
        [item.course_id]: item
      })
      this.flagArr = Object.assign({}, this.flagArr, {
        [item.course_id]: checked
      })
    },
    //课程查询
    getList($state) {
      const self = this
      if (this.activeCourseId) {
        self.requestParam['course_id'] = this.activeCourseId
      }
      const data = self.requestParam
      self.finiteState = $state
      self.isLoading = true
      self.isShowEmpty = false
      self.$http({
        name: API.searchCourse_Api.name,
        url: API.searchCourse_Api.url,
        data,
        callback({ code, data }) {
          if (code == 200) {
            self.isLoading = false
            self.isShowEmpty = false
            self.requestParam.page += 1
            self.list = self.list.concat(data)
            self.$nextTick($state.loaded)
          }
        },
        error({ code }) {
          if (code == 204) {
            self.isLoading = false
            if (self.requestParam.page == 1) {
              self.isShowEmpty = true
            }
          }
          $state.loaded()
          $state.complete()
        }
      })
    },

    //点击确认
    makeSubmit() {
      this.$emit('getCourseList', this.resultObj, 2)
      this.closeFn()
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-checkbox:last-of-type {
  margin-right: 20px;
}
.tabs {
  display: flex;
  border-bottom: 1px solid #eeeeee;
  .tab {
    display: inline-block;
    padding: 13px 28px;
    font-size: 14px;
    color: #333;
    border-bottom: 1px solid #fff;
    transition: all 0.3s;
  }
  .active {
    color: #0aa29b;
    border-bottom: 1px solid #0aa29b;
  }
}
.hope {
  position: absolute;
  display: block;
  top: -62px;
  left: 112px;
  width: 40px;
  height: 14px;
  background: url('../../assets/img/img_jqqd@2x.png') no-repeat;
  background-size: cover;
}
.hope1 {
  position: absolute;
  display: block;
  top: -62px;
  left: 190px;
  width: 40px;
  height: 14px;
  background: url('../../assets/img/img_jqqd@2x.png') no-repeat;
  background-size: cover;
}
.hope2 {
  position: absolute;
  display: block;
  top: -62px;
  left: 272px;
  width: 40px;
  height: 14px;
  background: url('../../assets/img/img_jqqd@2x.png') no-repeat;
  background-size: cover;
}
.edit-wrap {
  // ::v-deep .el-tabs__item.is-active,
  // ::v-deep .el-tabs__item:hover {
  //   color: #0aa29b;
  // }
  ::v-deep .el-tabs__content {
    overflow: visible;
  }
  ::v-deep .el-tabs__item.is-active {
    color: #0aa29b;
  }
  ::v-deep .el-tabs__active-bar {
    background-color: #0aa29b;
  }
  ::v-deep .el-tabs__item {
    height: 44px;
    line-height: 44px;
  }
  .tab-wrap {
    padding: 20px;
    box-sizing: border-box;
  }
  .ulBox {
    height: 300px;
    // text-align: center;
    overflow: auto;
  }
  // ul {
  //   clear: both;
  //   height: 400px;
  //   overflow-y: scroll;
  // }
}
.control-box {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 32px;
  .left-box {
    display: flex;
    align-items: center;
    ::v-deep .el-button--success {
      color: #fff;
      background-color: #0aa29b;
      border-color: #0aa29b;
    }
    .setting-erweima {
      margin-right: 20px;
      font-size: 12px;
      color: rgba(10, 162, 155, 1);
      line-height: 16px;
    }
  }
  .right-box {
    display: flex;
    align-items: center;
    .select-wrap {
      width: 120px;
      margin-left: 10px;
    }
    .input-wrap {
      margin-left: 10px;
      width: 218px;
    }
  }
}
::v-deep .el-checkbox__input {
  cursor: pointer;
  line-height: 1;
  // height: 38px;
}
::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #0aa29b;
  border-color: #0aa29b;
}

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #0aa29b;
}
::v-deep .el-input__inner:focus-within {
  border-color: #0aa29b;
}
::v-deep .el-checkbox__inner:hover {
  border-color: #0aa29b;
}
::v-deep .el-scrollbar .el-select-dropdown__item.selected {
  color: #0aa29b;
  font-weight: 700;
}
.courseItem {
  position: relative;
  display: flex;
  // width: 50%;
  height: 38px;
  line-height: 38px;
  align-items: center;
  margin-bottom: 33px;
  .little-mask-block {
    height: 100%;
    width: 34px;
    position: absolute;
    background: transparent;
    left: 0;
    top: 0;
    z-index: 80;
  }
  .checkbox-wrap {
    position: absolute;
    left: 0;
    top: 39px;
  }
  .courseImg {
    width: 68px;
    height: 38px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .courseName {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 14px;
    .title {
      width: 280px;
      height: 19px;
      font-size: 14px;
      color: rgba(51, 51, 51, 1);
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .courseCate {
      font-size: 12px;
      color: #ff3535;
      line-height: 16px;
    }
  }
  .delete {
    width: 12px;
    height: 12px;
    cursor: pointer;
    color: #999;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
