<template>
  <div class="associatedBox" :style="`padding-top:${isEdit ? '30px' : '10px'}`">
    <div class="addContentButton button2" @click="toggleShowSelectedContent">
      添加内容
    </div>
    <div
      class="content"
      v-if="courseList != undefined && courseList.length > 0"
    >
      <div
        class="courseItem"
        v-for="(item, index) in courseList"
        :key="index"
        @mouseenter="showDelet(index)"
        @mouseleave="hideDelet()"
      >
        <div class="courseImg">
          <img :src="item.photo" />
        </div>
        <div class="courseName">
          <div class="title">
            {{ item.name }}
          </div>
          <!-- 课程类型 1直播 2小班 3录播 4系列 -->
          <div class="courseCate" v-if="item.course_type == 1">直播课</div>
          <div class="courseCate" v-if="item.course_type == 2">小班课</div>
          <div class="courseCate" v-if="item.course_type == 3">录播课</div>
          <div class="courseCate" v-if="item.course_type == 4">系列课</div>
        </div>
        <div
          class="delete"
          @click="deleteCourse(index, item)"
          v-show="seen && index == current"
        >
          <img src="../../assets/img/ico_gbts.png" alt="" />
        </div>
      </div>
    </div>
    <template v-if="!isEdit">
      <div
        class="empty-wrap"
        v-show="courseList == undefined || courseList.length == 0"
      >
        <empty :isOld="false" :positionCenter="true"></empty>
      </div>
    </template>
    <template>
      <div class="empty-wrap" style="height: 430px" v-show="isEmptyStatus">
        <empty :isOld="false" :positionCenter="true"></empty>
      </div>
    </template>
    <loading v-show="isNoData" leftPos="0"></loading>
    <!-- 保存按钮 -->
    <div class="saveButton-wrap" v-if="isEdit">
      <el-button type="success" @click="doSave">保存</el-button>
    </div>
    <addContentPop
      v-if="showDetail"
      @closeFn="showDetail = false"
      @getCourseList="getCourseList"
      :activeCourseId="activeCourseId"
    ></addContentPop>
    <CourseConfirm
      v-if="delId"
      title="取消关联"
      :isSmallPop="true"
      @cancelFn="toggleShowDel"
      @save="deleteContent(delId)"
    >
      <h4 class="reset-txt">
        {{ '是否取消该内容？' }}
      </h4>
    </CourseConfirm>
  </div>
</template>
<script>
import addContentPop from '@/components/coursePop/addContentPop'
import CourseConfirm from '@/components/CourseConfirm'
import API from '@/request/api'
import empty from '@/components/Empty'
import loading from '@/components/LoadGif'

export default {
  name: 'AddContent',
  components: {
    addContentPop,
    empty,
    CourseConfirm,
    loading,
  },
  props: {
    courseType: [String, Number],
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      course_series: [],

      showDetail: false,

      seen: false,

      current: 0,

      // 关联内容列表
      courseList: [],

      // 要删除的项
      delId: '',
      activeCourseId: '',
      isEmptyStatus: false,
      isNoData: false,
    }
  },

  watch: {
    'courseList.length'() {
      this.$emit('titleChange', 5, this.courseList.length)
    },
  },

  created() {
    if (this.isEdit) {
      this.editScheduleInfo()
    }
  },

  methods: {
    toggleShowSelectedContent() {
      this.showDetail = !this.showDetail
      if (this.isEdit && this.showDetail) {
        const hasCorrelation_id = this.course_series.every(
          (item) => !item.correlation_id
        )
        this.activeCourseId = hasCorrelation_id
          ? ''
          : this.$route.query.course_id
      }
    },
    cancel() {
      this.$emit('close')
    },
    showDelet(index) {
      this.seen = true
      this.current = index
    },
    hideDelet() {
      this.seen = false
      this.current = null
    },
    deleteCourse(index, item) {
      this.delId = `${
        item.correlation_id ? item.correlation_id : 'no-correlation_id'
      }&${index}`
      // if (item.correlation_id && this.isEdit) {

      // } else {
      //   this.courseList.splice(index, 1);
      //   this.course_series.splice(index, 1);
      // }
    },
    toggleShowDel() {
      this.delId = ''
    },
    // 编辑时获取信息
    editScheduleInfo() {
      const self = this
      const course_id = this.$route.query.course_id
      self.isNoData = true
      self.$http({
        name: API.editScheduleInfo_Api.name,
        url: `${API.editScheduleInfo_Api.url}?course_id=${course_id}&course_type=${self.courseType}`,
        callback({ code, data }) {
          if (code == 200) {
            self.isNoData = false
            // self.isEmptyStatus = false;
            let tempObj = {}
            if (data.length > 0) {
              data.forEach((item) => {
                tempObj = Object.assign({}, tempObj, {
                  // [item.course_id]: item
                  [item.correlation_id]: item,
                })
              })
            }
            self.getCourseList(tempObj, 1)
          }
        },
        error({ code }) {
          if (code == 204) {
            self.isNoData = false
            self.isEmptyStatus = true
          }
        },
      })
    },
    // 保存
    doSave() {
      const self = this
      // if (self.course_series.length == 0) {
      //   self.$root.prompt("请先选择内容");
      //   return;
      // }
      const paramData = {
        course_series: self.course_series,
        course_type: self.courseType,
      }
      if (self.isEdit) {
        paramData['course_id'] = self.$route.query.course_id
      }
      self.$http({
        name: API.editScheduleInfo_Api.name,
        url: API.editScheduleInfo_Api.url,
        data: paramData,
        callback({ code }) {
          if (code == 200) {
            self.$root.prompt({
              msg: '保存成功',
              type: 'success',
            })
            self.$emit('updateheader3')
            self.courseList = []
            self.course_series = []
            self.editScheduleInfo()
          }
        },
      })
    },
    // 删除内容
    deleteContent(correlation_id) {
      const self = this
      const param = correlation_id.split('&')
      if (param[0] == 'no-correlation_id') {
        self.courseList.splice(param[1], 1)
        self.course_series.splice(param[1], 1)
        self.toggleShowDel()
        if (this.isEdit) {
          if (this.courseList.length > 0) {
            this.isEmptyStatus = false
          } else {
            this.isEmptyStatus = true
          }
        }
        return
      }
      self.$http({
        name: API.delCourseSeries_Api.name,
        url: API.delCourseSeries_Api.url,
        data: {
          id: param[0],
        },
        callback({ code }) {
          if (code == 200) {
            self.courseList.splice(param[1], 1)
            self.course_series.splice(param[1], 1)
            self.toggleShowDel()
            self.$root.prompt({
              msg: '删除成功',
              type: 'success',
            })
            if (self.courseList.length == 0) {
              self.editScheduleInfo()
            }
          }
        },
      })
    },
    // 获取数据
    getCourseList(obj, type) {
      const tempObj = Object.keys(obj)
      let ids = [],
        tempArr = [],
        newAdd = []
      if (this.courseList.length > 0) {
        this.courseList.forEach((item) => {
          // ids.
          //   this.isEdit
          //     ? item.series_course_id || item.course_id
          //     : item.course_id
          // );
          ids.push(
            this.isEdit ? item.correlation_id || item.course_id : item.course_id
          )
        })
      }
      if (tempObj.length > 0) {
        tempObj.forEach((key) => {
          tempArr.push(obj[key])
        })
      }
      if (tempArr.length > 0) {
        newAdd = tempArr.filter((v) => {
          return !ids.includes(type == 1 ? v.correlation_id : v.course_id)
        })
      }
      for (let i = 0; i < newAdd.length; i += 1) {
        let param = {
          id: newAdd[i].course_id,
        }
        if (this.isEdit && type == 1) {
          param['correlation_id'] = newAdd[i].correlation_id
        }
        this.courseList.push(newAdd[i])
        this.course_series.push(param)
      }
      if (this.isEdit) {
        if (this.courseList.length > 0) {
          this.isEmptyStatus = false
        } else {
          this.isEmptyStatus = true
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.associatedBox {
  box-sizing: border-box;
  .addContentButton {
    display: inline-block;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    background: #fff;
    border: 1px solid #0aa29b;
    color: #0aa29b;
    -webkit-appearance: none;
    text-align: center;
    box-sizing: border-box;
    outline: none;
    margin: 0;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    padding: 12px 30px;
    font-size: 14px;
    border-radius: 4px;
  }
  .button2 {
    background: #0aa29b;
    color: #fff;
  }
  .content {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 30px;
    padding-bottom: 40px;
    box-sizing: border-box;
    .courseItem {
      display: flex;
      width: 50%;
      height: 38px;
      line-height: 38px;
      align-items: center;
      margin-bottom: 33px;
      .courseImg {
        width: 68px;
        height: 38px;
        margin-right: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .courseName {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 14px;
        .title {
          width: 280px;
          height: 19px;
          font-size: 14px;
          color: rgba(51, 51, 51, 1);
          line-height: 19px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .courseCate {
          font-size: 12px;
          color: rgba(153, 153, 153, 1);
          line-height: 16px;
        }
      }
      .delete {
        width: 12px;
        height: 12px;
        cursor: pointer;
        color: #999;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .saveButton {
    // display: inline-block;
    // position: absolute;
    // bottom: -30px;
    // left: 0;
    width: 126px;
    cursor: pointer;
    height: 42px;
    line-height: 42px;
    text-align: center;
    font-size: 16px;
    border-radius: 4px;
    color: #fff;
    background: rgba(10, 162, 155, 1);
    border: rgba(10, 162, 155, 1);
  }
}
.saveButton-wrap {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 7px 0 7px 208px;
  box-sizing: border-box;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.06);
  background: #fff;
  text-align: center;
  .saveButton {
    width: 100px;
    margin: 0 auto;
  }
}
.saveButton-wrap ::v-deep.el-button--success {
  width: 100px;
  color: #fff;
  display: inline-block;
  background-color: #0aa29b;
  border-color: #0aa29b;
}
.empty-wrap {
  position: relative;
  height: 200px;
}
.reset-txt {
  padding: 10px 20px 0 20px;
  margin-bottom: 70px;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  line-height: 24px;
}
</style>
